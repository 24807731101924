define("dome/components/main-header", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsNavbar class="main-header navbar-white" @backgroundColor="none" as |navbar|>
    <navbar.nav as |nav|>
      <nav.item>
        <BsButton @type="link" class="border-0 nav-link" {{alte/push-menu enableRemember=true}}>
          <FaIcon @icon="bars" />
        </BsButton>
      </nav.item>
    </navbar.nav>
  </BsNavbar>
  
  */
  {
    "id": "whFjTziL",
    "block": "[[[8,[39,0],[[24,0,\"main-header navbar-white\"]],[[\"@backgroundColor\"],[\"none\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"nav\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],[[24,0,\"border-0 nav-link\"],[4,[38,2],null,[[\"enableRemember\"],[true]]]],[[\"@type\"],[\"link\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@icon\"],[\"bars\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"navbar\",\"nav\"],false,[\"bs-navbar\",\"bs-button\",\"alte/push-menu\",\"fa-icon\"]]",
    "moduleName": "dome/components/main-header.hbs",
    "isStrictMode": false
  });

  class MainHeader extends _component.default {}

  _exports.default = MainHeader;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MainHeader);
});