define("dome/pods/index/controller", ["exports", "dome/objects/ac4/gantt-data", "dome/objects/ac4/stacked-history-data", "dome/objects/ac4/stacked-history-element-data", "dome/objects/depenses-annuelles"], function (_exports, _ganttData, _stackedHistoryData, _stackedHistoryElementData, _depensesAnnuelles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let IndexController = (_dec = Ember.computed('model.contrats'), _dec2 = Ember.computed('model.depenses'), _dec3 = Ember.computed('depensesAnnuelles'), (_class = class IndexController extends Ember.Controller {
    get contrats() {
      return this.model.contrats.reduce((contrats, contrat) => {
        let category = contrat.sousType;

        if (contrats.findIndex(c => c.category === category) !== -1) {
          category += ' 2';
        }

        const data = new _ganttData.default(category, contrat.sousType, contrat.dateDebut, contrat.dateFinEstime, contrat.fournisseur);
        contrats.push(data);
        return contrats;
      }, new Array());
    }

    get depensesAnnuelles() {
      const annees = this.model.depenses.reduce((depenses, depense) => {
        if (!depenses.some(d => d.exercice === depense.exercice)) {
          depenses.push(new _depensesAnnuelles.default(depense.exercice, []));
        }

        const depensesByExercice = depenses.find(d => d.exercice === depense.exercice);
        depensesByExercice.addDepense(depense);
        return depenses;
      }, new Array());
      return annees.sort((a, b) => {
        return b.exercice - a.exercice;
      });
    }

    get historyData() {
      const labels = [];
      const elements = this.depensesAnnuelles.reduce((values, value) => {
        const current = new _stackedHistoryElementData.default(new Date(value.exercice, 8, 30));
        values.push(current);
        value.depenses.forEach(depense => {
          let index = labels.indexOf(depense.sousType) + 1;

          if (index === 0) {
            labels.push(depense.sousType);
            index = labels.length;
          }

          if (current[index] === undefined) {
            current[index] = 0;
          }

          current[index] = parseFloat(depense.montant.add(current[index].toString()).toFixed(2));
        });
        return values;
      }, new Array());
      elements.forEach(e => {
        for (let i = 1; i < labels.length + 1; i++) {
          if (e[i] === undefined || e[i] < 0) {
            e[i] = 0;
          }
        }
      });
      return new _stackedHistoryData.default('€', labels, elements);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "contrats", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "contrats"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "depensesAnnuelles", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "depensesAnnuelles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "historyData", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "historyData"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.

  _exports.default = IndexController;
});