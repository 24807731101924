define("dome/components/footer", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer class="main-footer">
    <strong>
      Copyright &copy; 2021
      <a href="https://dome.olj.xyz">
        Dome
      </a>
    </strong>
    <div class="float-right d-none d-sm-inline-block">
      <b>
        Version
      </b>
      {{app-version versionOnly=true}}
    </div>
  </footer>
  
  */
  {
    "id": "/IILb9sR",
    "block": "[[[10,\"footer\"],[14,0,\"main-footer\"],[12],[1,\"\\n  \"],[10,\"strong\"],[12],[1,\"\\n    Copyright © 2021\\n    \"],[10,3],[14,6,\"https://dome.olj.xyz\"],[12],[1,\"\\n      Dome\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"float-right d-none d-sm-inline-block\"],[12],[1,\"\\n    \"],[10,\"b\"],[12],[1,\"\\n      Version\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"versionOnly\"],[true]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"app-version\"]]",
    "moduleName": "dome/components/footer.hbs",
    "isStrictMode": false
  });

  class Footer extends _component.default {}

  _exports.default = Footer;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Footer);
});