define("dome/pods/charges/entretien-menager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ai7x/wM1",
    "block": "[[[8,[39,0],null,[[\"@name\"],[\"Détail de l'entretien ménager\"]],null],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container-fluid\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-12\"],[12],[1,\"\\n        \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[39,2],null,[[\"@title\",\"@titleIcon\"],[\"Evolution des prix\",\"chart-pie\"]],null],[1,\"\\n          \"],[8,[39,3],[[24,0,\"p-0\"]],null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@data\"],[[30,0,[\"pricesData\"]]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"alte/content-header\",\"alte/card\",\"alte/card/header\",\"alte/card/body\",\"ac4/bars\"]]",
    "moduleName": "dome/pods/charges/entretien-menager/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});