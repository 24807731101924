define("dome/objects/ac4/bars-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AmCharts4BarsData {
    constructor(date, amount) {
      this.date = date;
      this.amount = amount;
    }

    get amountAsNumber() {
      return parseFloat(this.amount.toFixed(2));
    }

  }

  _exports.default = AmCharts4BarsData;
});