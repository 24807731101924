define("dome/pods/index/route", ["exports", "bigint-money", "dome/objects/contrat", "dome/objects/depense"], function (_exports, _bigintMoney, _contrat, _depense) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends Ember.Route {
    async model() {
      const depensesJson = await fetch('/assets/data/depenses.json');
      const depensesPayload = await depensesJson.json();
      const depenses = depensesPayload.map(dr => new _depense.default(parseInt(dr.exercice), dr.type, dr.soustype, new Date(dr.date), dr.libelle, dr.fournisseur, new _bigintMoney.default(dr.montant, 'EUR')));
      const contratsJson = await fetch('/assets/data/contrats.json');
      const contratsPayload = await contratsJson.json();
      const contrats = contratsPayload.map(cr => new _contrat.default(cr.fournisseur, cr.reference, new Date(parseInt(cr.datedebut.substring(6)), parseInt(cr.datedebut.substring(3, 5)) - 1, parseInt(cr.datedebut.substring(0, 2))), cr.datefin ? new Date(parseInt(cr.datefin.substring(6)), parseInt(cr.datefin.substring(3, 5)) - 1, parseInt(cr.datefin.substring(0, 2))) : undefined, cr.validiteinitiale ? parseInt(cr.validiteinitiale) : undefined, cr.validite ? parseInt(cr.validite) : undefined, cr.preavis ? parseFloat(cr.preavis) : undefined, cr.soustype));
      return {
        contrats: contrats,
        depenses: depenses
      };
    }

  }

  _exports.default = IndexRoute;
});