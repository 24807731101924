define("dome/objects/depenses-annuelles", ["exports", "dome/utils/moneys"], function (_exports, _moneys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DepensesAnnuelles = (_dec = Ember.computed('depenses.[]'), _dec2 = Ember.computed('depenses.[]'), (_class = class DepensesAnnuelles {
    constructor(exercice, depenses) {
      _defineProperty(this, "depenses", []);

      _defineProperty(this, "total", _moneys.default.ZERO_EUR);

      this.exercice = exercice;
      depenses.forEach(d => this.addDepense(d));
    }

    addDepense(depense) {
      this.depenses.push(depense);
      this.total = this.total.add(depense.montant);
    }

    get depensesByType() {
      return this.depenses.reduce((depensesByType, value) => {
        if (!depensesByType.has(value.type)) {
          depensesByType.set(value.type, value.montant);
        } else {
          depensesByType.set(value.type, depensesByType.get(value.type).add(value.montant));
        }

        return depensesByType;
      }, new Map());
    }

    get electricityConsumption() {
      const electricityConsumptions = this.depenses.filter(d => d.type === '700 CHAUFFAGE');
      return electricityConsumptions.reduce((consumption, depense) => {
        const result = depense.libelle.match(/(\d*) KWH/);

        if ((result === null || result === void 0 ? void 0 : result.length) === 2) {
          consumption = consumption + parseInt(result[1]);
        }

        return consumption;
      }, 0);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "depensesByType", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "depensesByType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "electricityConsumption", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "electricityConsumption"), _class.prototype)), _class));
  _exports.default = DepensesAnnuelles;
});