define("dome/components/alte/main-sidebar/tree-view", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="nav-item has-treeview {{if this.isOpen " menu-open"}}">
    <a href="#" class="nav-link" onclick={{this.toggle}}>
      {{#if @icon}}
        {{#if @iconPrefix}}
          <FaIcon class="nav-icon" @prefix={{@iconPrefix}} @icon={{@icon}} />
        {{else}}
          <FaIcon class="nav-icon" @icon={{@icon}} />
        {{/if}}
      {{/if}}
      <p>
        {{@label}}
        <FaIcon class="right" @icon={{if this.isOpen "angle-down" "angle-left" }} />
        {{#if @badgeLabel}}
          <span class="badge badge-info right">{{@badgeLabel}}</span>
        {{/if}}
      </p>
    </a>
    {{yield}}
  </li>
  
  */
  {
    "id": "LcQCFBNi",
    "block": "[[[10,\"li\"],[15,0,[29,[\"nav-item has-treeview \",[52,[30,0,[\"isOpen\"]],\" menu-open\"]]]],[12],[1,\"\\n  \"],[10,3],[14,6,\"#\"],[14,0,\"nav-link\"],[15,\"onclick\",[30,0,[\"toggle\"]]],[12],[1,\"\\n\"],[41,[30,1],[[[41,[30,2],[[[1,\"        \"],[8,[39,1],[[24,0,\"nav-icon\"]],[[\"@prefix\",\"@icon\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,1],[[24,0,\"nav-icon\"]],[[\"@icon\"],[[30,1]]],null],[1,\"\\n\"]],[]]]],[]],null],[1,\"    \"],[10,2],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n      \"],[8,[39,1],[[24,0,\"right\"]],[[\"@icon\"],[[52,[30,0,[\"isOpen\"]],\"angle-down\",\"angle-left\"]]],null],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[10,1],[14,0,\"badge badge-info right\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@icon\",\"@iconPrefix\",\"@label\",\"@badgeLabel\",\"&default\"],false,[\"if\",\"fa-icon\",\"yield\"]]",
    "moduleName": "dome/components/alte/main-sidebar/tree-view.hbs",
    "isStrictMode": false
  });

  let MainSidebarTreeView = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class MainSidebarTreeView extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isOpen", _descriptor, this);
    }

    toggle(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isOpen = !this.isOpen;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  _exports.default = MainSidebarTreeView;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MainSidebarTreeView);
});