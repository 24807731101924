define("dome/components/ac4/gantt", ["exports", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/core", "@amcharts/amcharts4/themes/animated", "@amcharts/amcharts4/themes/material", "@glimmer/component"], function (_exports, _charts, _core, _animated, _material, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _chart;

  function _classPrivateFieldGet(receiver, privateMap) { var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "get"); return _classApplyDescriptorGet(receiver, descriptor); }

  function _classApplyDescriptorGet(receiver, descriptor) { if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

  function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "set"); _classApplyDescriptorSet(receiver, descriptor, value); return value; }

  function _classExtractFieldDescriptor(receiver, privateMap, action) { if (!privateMap.has(receiver)) { throw new TypeError("attempted to " + action + " private field on non-instance"); } return privateMap.get(receiver); }

  function _classApplyDescriptorSet(receiver, descriptor, value) { if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="amchart" {{did-insert this.renderChart}} {{did-update this.updateChart @data}} />
  
  */
  {
    "id": "TJFTTdpW",
    "block": "[[[11,0],[24,0,\"amchart\"],[4,[38,0],[[30,0,[\"renderChart\"]]],null],[4,[38,1],[[30,0,[\"updateChart\"]],[30,1]],null],[12],[13],[1,\"\\n\"]],[\"@data\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "dome/components/ac4/gantt.hbs",
    "isStrictMode": false
  });

  let AmCharts4Gantt = (_dec = Ember._action, _dec2 = Ember._action, (_class = (_chart = new WeakMap(), class AmCharts4Gantt extends _component.default {
    constructor(...args) {
      super(...args);

      _chart.set(this, {
        writable: true,
        value: void 0
      });
    }

    renderChart(element) {
      (0, _core.useTheme)(_animated.default);
      (0, _core.useTheme)(_material.default);

      _classPrivateFieldSet(this, _chart, (0, _core.create)(element, _charts.XYChart));

      _classPrivateFieldGet(this, _chart).hiddenState.properties.opacity = 0; // this creates initial fade-in

      _classPrivateFieldGet(this, _chart).paddingLeft = 20;
      _classPrivateFieldGet(this, _chart).paddingRight = 20;

      if (this.args.data) {
        _classPrivateFieldGet(this, _chart).data = this.args.data;
      }

      _classPrivateFieldGet(this, _chart).dateFormatter.dateFormat = 'dd/MM/yyyy';

      let categoryAxis = _classPrivateFieldGet(this, _chart).yAxes.push(new _charts.CategoryAxis());

      categoryAxis.dataFields.category = 'category';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.disabled = true;

      if (categoryAxis.tooltip) {
        categoryAxis.tooltip.disabled = true;
      }

      let dateAxis = _classPrivateFieldGet(this, _chart).xAxes.push(new _charts.DateAxis());

      dateAxis.renderer.minGridDistance = 70;
      dateAxis.baseInterval = {
        count: 1,
        timeUnit: 'day'
      };
      dateAxis.tooltipDateFormat = 'dd/MM/yyyy';

      let series1 = _classPrivateFieldGet(this, _chart).series.push(new _charts.ColumnSeries());

      series1.columns.template.height = (0, _core.percent)(70);
      series1.columns.template.tooltipText = '{categoryLabel} - {task}: [bold]{openDateX}[/] - [bold]{dateX}[/]';
      series1.dataFields.openDateX = 'start';
      series1.dataFields.dateX = 'end';
      series1.dataFields.categoryY = 'category';
      series1.columns.template.strokeOpacity = 0;
      series1.columns.template.adapter.add('fill', (_fill, target) => {
        var _classPrivateFieldGet2, _target$dataItem;

        return (_classPrivateFieldGet2 = _classPrivateFieldGet(this, _chart)) === null || _classPrivateFieldGet2 === void 0 ? void 0 : _classPrivateFieldGet2.colors.getIndex((_target$dataItem = target.dataItem) === null || _target$dataItem === void 0 ? void 0 : _target$dataItem.index);
      });
      _classPrivateFieldGet(this, _chart).cursor = new _charts.XYCursor();
      _classPrivateFieldGet(this, _chart).cursor.xAxis = dateAxis;
      _classPrivateFieldGet(this, _chart).scrollbarX = new _core.Scrollbar();
    }

    updateChart() {
      if (_classPrivateFieldGet(this, _chart)) {
        if (this.args.data) {
          _classPrivateFieldGet(this, _chart).data = this.args.data;
        }

        _classPrivateFieldGet(this, _chart).invalidateData();
      }
    }

    willDestroy() {
      if (_classPrivateFieldGet(this, _chart)) {
        _classPrivateFieldGet(this, _chart).dispose();
      }
    }

  }), (_applyDecoratedDescriptor(_class.prototype, "renderChart", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateChart", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateChart"), _class.prototype)), _class));
  _exports.default = AmCharts4Gantt;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AmCharts4Gantt);
});