define("dome/components/ac4/bars", ["exports", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/core", "@amcharts/amcharts4/themes/animated", "@amcharts/amcharts4/themes/material", "@glimmer/component", "dome/utils/moneys"], function (_exports, _charts, _core, _animated, _material, _component, _moneys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _chart;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _classPrivateFieldGet(receiver, privateMap) { var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "get"); return _classApplyDescriptorGet(receiver, descriptor); }

  function _classApplyDescriptorGet(receiver, descriptor) { if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

  function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "set"); _classApplyDescriptorSet(receiver, descriptor, value); return value; }

  function _classExtractFieldDescriptor(receiver, privateMap, action) { if (!privateMap.has(receiver)) { throw new TypeError("attempted to " + action + " private field on non-instance"); } return privateMap.get(receiver); }

  function _classApplyDescriptorSet(receiver, descriptor, value) { if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="amchart" {{did-insert this.renderChart}} {{did-update this.updateChart @data}} />
  
  */
  {
    "id": "FAgA78fs",
    "block": "[[[11,0],[24,0,\"amchart\"],[4,[38,0],[[30,0,[\"renderChart\"]]],null],[4,[38,1],[[30,0,[\"updateChart\"]],[30,1]],null],[12],[13],[1,\"\\n\"]],[\"@data\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "dome/components/ac4/bars.hbs",
    "isStrictMode": false
  });

  let AmCharts4Bars = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_chart = new WeakMap(), class AmCharts4Bars extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _chart.set(this, {
        writable: true,
        value: void 0
      });

      _defineProperty(this, "formatTooltip", (text, target) => {
        const data = target.tooltipDataItem.dataContext;

        if (data) {
          return _moneys.default.format(this.intl, data.amount, 2);
        } else {
          return text;
        }
      });
    }

    renderChart(element) {
      (0, _core.useTheme)(_animated.default);
      (0, _core.useTheme)(_material.default);

      _classPrivateFieldSet(this, _chart, (0, _core.create)(element, _charts.XYChart));

      _classPrivateFieldGet(this, _chart).hiddenState.properties.opacity = 0; // this creates initial fade-in

      _classPrivateFieldGet(this, _chart).paddingLeft = 20;
      _classPrivateFieldGet(this, _chart).paddingRight = 20;
      _classPrivateFieldGet(this, _chart).colors.step = 2;
      _classPrivateFieldGet(this, _chart).maskBullets = false;

      if (this.args.data) {
        _classPrivateFieldGet(this, _chart).data = this.args.data;
      } // Create axes


      let dateAxis = _classPrivateFieldGet(this, _chart).xAxes.push(new _charts.DateAxis());

      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 50;
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.renderer.fullWidthTooltip = true;
      dateAxis.baseInterval = {
        count: 1,
        timeUnit: 'month'
      };
      dateAxis.tooltipDateFormat = 'MM/yyyy';

      let montantAxis = _classPrivateFieldGet(this, _chart).yAxes.push(new _charts.ValueAxis());

      montantAxis.title.text = 'Montant'; //distanceAxis.renderer.grid.template.disabled = true;
      // Create series

      let montantSeries = _classPrivateFieldGet(this, _chart).series.push(new _charts.ColumnSeries());

      montantSeries.dataFields.valueY = 'amountAsNumber';
      montantSeries.dataFields.dateX = 'date';
      montantSeries.yAxis = montantAxis; //distanceSeries.tooltipText = '{valueY} miles';

      montantSeries.adapter.add('tooltipText', this.formatTooltip);
      montantSeries.name = 'Prix'; // montantSeries.columns.template.fillOpacity = 0.7;
      // montantSeries.columns.template.propertyFields.strokeDasharray = 'dashLength';
      // montantSeries.columns.template.propertyFields.fillOpacity = 'alpha';

      montantSeries.showOnInit = true; // Add legend

      _classPrivateFieldGet(this, _chart).legend = new _charts.Legend(); // Add cursor

      _classPrivateFieldGet(this, _chart).cursor = new _charts.XYCursor();
      _classPrivateFieldGet(this, _chart).cursor.fullWidthLineX = true;
      _classPrivateFieldGet(this, _chart).cursor.xAxis = dateAxis;
      _classPrivateFieldGet(this, _chart).cursor.lineX.strokeOpacity = 0;
      _classPrivateFieldGet(this, _chart).cursor.lineX.fill = (0, _core.color)('#000');
      _classPrivateFieldGet(this, _chart).cursor.lineX.fillOpacity = 0.1;
    }

    updateChart() {
      if (_classPrivateFieldGet(this, _chart)) {
        if (this.args.data) {
          _classPrivateFieldGet(this, _chart).data = this.args.data;
        }

        _classPrivateFieldGet(this, _chart).invalidateData();
      }
    }

    willDestroy() {
      if (_classPrivateFieldGet(this, _chart)) {
        _classPrivateFieldGet(this, _chart).dispose();
      }
    }

  }), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderChart", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateChart", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateChart"), _class.prototype)), _class));
  _exports.default = AmCharts4Bars;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AmCharts4Bars);
});