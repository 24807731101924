define("dome/pods/exercice/controller", ["exports", "dome/objects/ac4/sunburst-data"], function (_exports, _sunburstData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExerciceController extends Ember.Controller {
    get sunburstDataByHoldingType() {
      return this.model.depenses.reduce((values, value) => {
        let data = values.find(v => v.name === value.sousType);

        if (!data) {
          data = new _sunburstData.AmCharts4SunburstData(value.sousType, value.type, value.montant);
          values.push(data);
        } else {
          data.value = data.value.add(value.montant);
        }

        return values;
      }, new Array());
    }

  } // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.


  _exports.default = ExerciceController;
});