define("dome/components/ac4/stacked-history", ["exports", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/core", "@amcharts/amcharts4/themes/animated", "@amcharts/amcharts4/themes/material", "@glimmer/component"], function (_exports, _charts, _core, _animated, _material, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="amchart" {{did-insert this.renderChart}} {{did-update this.updateChart @data}} />
  
  */
  {
    "id": "02+55leh",
    "block": "[[[11,0],[24,0,\"amchart\"],[4,[38,0],[[30,0,[\"renderChart\"]]],null],[4,[38,1],[[30,0,[\"updateChart\"]],[30,1]],null],[12],[13],[1,\"\\n\"]],[\"@data\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "dome/components/ac4/stacked-history.hbs",
    "isStrictMode": false
  });

  let AmCharts4StackedHistory = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class AmCharts4StackedHistory extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "chart", void 0);
    }

    renderChart(element) {
      (0, _core.useTheme)(_animated.default);
      (0, _core.useTheme)(_material.default);
      this.chart = (0, _core.create)(element, _charts.XYChart);

      if (this.args.data.elements.length > 0) {
        this.chart.data = this.args.data.elements;
        const keys = Object.keys(this.args.data.elements[0]);
        const ids = keys.filter(e => parseInt(e, 10) > 0);
        this.chart.dateFormatter.dateFormat = 'dd/MM/yyyy';
        const dateAxis = this.chart.xAxes.push(new _charts.DateAxis());
        dateAxis.renderer.minGridDistance = 60;
        dateAxis.baseInterval = {
          timeUnit: 'day',
          count: 1
        };
        dateAxis.tooltipDateFormat = 'dd/MM/yyyy';
        const valueAxis = this.chart.yAxes.push(new _charts.ValueAxis());

        if (valueAxis.tooltip) {
          valueAxis.tooltip.disabled = true;
        }

        for (const [i, id] of ids.entries()) {
          const series = this.chart.series.push(new _charts.LineSeries());
          series.dataFields.dateX = 'date';
          series.name = this.args.data.labels[i];
          series.dataFields.valueY = id;

          if (series.tooltip) {
            // Set up tooltip
            series.adapter.add('tooltipText', (_defaultText, target) => {
              var _this$chart;

              const elementDataItem = target.tooltipDataItem;

              if (elementDataItem.valueY === 0) {
                return '';
              }

              let text = '';

              if (elementDataItem.dateX) {
                text = "[bold]Exercice ".concat(elementDataItem.dateX.getFullYear() - 1, "-").concat(elementDataItem.dateX.getFullYear(), "[/]\n");
              }

              (_this$chart = this.chart) === null || _this$chart === void 0 ? void 0 : _this$chart.series.each(item => {
                const tooltipDataItem = item.tooltipDataItem;

                if (tooltipDataItem.valueY > 0) {
                  text += "[".concat(item.stroke.hex, "]\u25CF[/] ").concat(item.name, " : ").concat(this.intl.formatNumber(tooltipDataItem.valueY, {
                    currency: 'EUR',
                    style: 'currency'
                  }), "\n");
                }
              });
              return text;
            });
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = (0, _core.color)('#fff');
            series.tooltip.label.fill = (0, _core.color)('#00'); // Prevent cross-fading of tooltips

            series.tooltip.defaultState.transitionDuration = 0;
            series.tooltip.hiddenState.transitionDuration = 0;
          }

          series.fillOpacity = 0.6;
          series.strokeWidth = 2;
          series.stacked = true;
        }

        this.chart.cursor = new _charts.XYCursor();
        this.chart.cursor.xAxis = dateAxis;
        this.chart.cursor.maxTooltipDistance = 0;
        this.chart.scrollbarX = new _core.Scrollbar(); // Add a legend

        this.chart.legend = new _charts.Legend();
        this.chart.legend.maxHeight = 120;
        this.chart.legend.scrollable = true;
      }
    }

    updateChart() {
      if (this.chart) {
        this.chart.data = this.args.data.elements;
        this.chart.invalidateData();
      }
    }

    willDestroy() {
      if (this.chart) {
        this.chart.dispose();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderChart", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateChart", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateChart"), _class.prototype)), _class));
  _exports.default = AmCharts4StackedHistory;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AmCharts4StackedHistory);
});