define("dome/objects/ac4/sunburst-data", ["exports", "dome/utils/moneys"], function (_exports, _moneys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AmCharts4SunburstData = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AmCharts4SunburstData {
    constructor(name, type, value) {
      _defineProperty(this, "name", '');

      _defineProperty(this, "type", '');

      _defineProperty(this, "value", _moneys.default.ZERO_EUR);

      this.name = name;
      this.type = type;
      this.value = value;
    }

    get valueAsNumber() {
      return parseFloat(this.value.toFixed(2));
    }

  }

  _exports.AmCharts4SunburstData = AmCharts4SunburstData;
});