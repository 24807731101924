define("dome/services/data-service", ["exports", "bigint-money", "dome/objects/depense"], function (_exports, _bigintMoney, _depense) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DataService extends Ember.Service {
    async getDepenses() {
      const depensesJson = await fetch('/assets/data/depenses.json');
      const depensesPayload = await depensesJson.json();
      return depensesPayload.map(dr => new _depense.default(parseInt(dr.exercice), dr.type, dr.soustype, new Date(dr.date), dr.libelle, dr.fournisseur, new _bigintMoney.default(dr.montant, 'EUR')));
    }

  } // DO NOT DELETE: this is how TypeScript knows how to look up your services.


  _exports.default = DataService;
});