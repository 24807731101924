define("dome/objects/contrat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Contrat = (_dec = Ember.computed('dateDebut', 'dateFin', 'validiteInitiale', 'validite'), (_class = class Contrat {
    constructor(fournisseur, reference, dateDebut, dateFin, validiteInitiale, validite, preavis, sousType) {
      this.fournisseur = fournisseur;
      this.reference = reference;
      this.dateDebut = dateDebut;
      this.dateFin = dateFin;
      this.validiteInitiale = validiteInitiale;
      this.validite = validite;
      this.preavis = preavis;
      this.sousType = sousType;
    }

    get dateFinEstime() {
      if (this.dateFin) {
        return this.dateFin;
      } else {
        let dateFin = new Date(this.dateDebut);

        if (this.validiteInitiale) {
          dateFin.setMonth(dateFin.getMonth() + this.validiteInitiale);
        }

        if (this.validite) {
          const today = new Date();

          while (dateFin < today) {
            dateFin.setMonth(dateFin.getMonth() + this.validite);
          }
        }

        dateFin.setDate(dateFin.getDate() - 1);
        return dateFin;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "dateFinEstime", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dateFinEstime"), _class.prototype)), _class));
  _exports.default = Contrat;
});