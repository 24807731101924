define("dome/objects/ac4/stacked-history-element-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AmCharts4StackedHistoryElementData {
    constructor(date) {
      this.date = date;
    }

  }

  _exports.default = AmCharts4StackedHistoryElementData;
});