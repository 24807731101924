define("dome/components/alte/layout", ["exports", "admin-lte-ember/components/alte/layout"], function (_exports, _layout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _layout.default;
    }
  });
});