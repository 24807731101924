define("dome/components/alte/breadcrumb", ["exports", "admin-lte-ember/components/alte/breadcrumb"], function (_exports, _breadcrumb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _breadcrumb.default;
    }
  });
});