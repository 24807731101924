define("dome/objects/depense", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Depense {
    constructor(exercice, type, sousType, date, libelle, fournisseur, montant) {
      this.exercice = exercice;
      this.type = type;
      this.sousType = sousType;
      this.date = date;
      this.libelle = libelle;
      this.fournisseur = fournisseur;
      this.montant = montant;
    }

  }

  _exports.default = Depense;
});