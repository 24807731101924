define("dome/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T1B7/EMW",
    "block": "[[[1,[28,[35,0],[\"Dome\"],null]],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[10,0],[14,0,\"content-wrapper\"],[12],[1,\"\\n    \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,6],null,null,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"page-title\",\"alte/layout\",\"main-header\",\"main-sidebar\",\"component\",\"-outlet\",\"footer\"]]",
    "moduleName": "dome/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});