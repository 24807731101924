define("dome/components/ac4/sunburst", ["exports", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/core", "@amcharts/amcharts4/plugins/sunburst", "@amcharts/amcharts4/themes/animated", "@amcharts/amcharts4/themes/material", "@glimmer/component"], function (_exports, _charts, _core, _sunburst, _animated, _material, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="amchart" {{did-insert this.renderChart}} {{did-update this.updateChart @data}} />
  
  */
  {
    "id": "Fct1zXGO",
    "block": "[[[11,0],[24,0,\"amchart\"],[4,[38,0],[[30,0,[\"renderChart\"]]],null],[4,[38,1],[[30,0,[\"updateChart\"]],[30,1]],null],[12],[13],[1,\"\\n\"]],[\"@data\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "dome/components/ac4/sunburst.hbs",
    "isStrictMode": false
  });

  (0, _core.useTheme)(_animated.default);
  (0, _core.useTheme)(_material.default);
  let Am4chartsSunburst = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class Am4chartsSunburst extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "chart", void 0);

      _defineProperty(this, "formatTooltip", (text, target) => {
        const data = target.tooltipDataItem.dataContext;

        if (data) {
          return "".concat(data.name, ": ").concat(this.intl.formatNumber(data.percent, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }), "% (").concat(this.intl.formatNumber(data.value, {
            currency: 'EUR',
            style: 'currency'
          }), ")");
        } else {
          return text;
        }
      });
    }

    renderChart(element) {
      this.chart = (0, _core.create)(element, _sunburst.Sunburst);
      this.chart.padding(0, 0, 0, 0);
      this.chart.radius = (0, _core.percent)(98);
      const dataGrouped = this.getDataGrouped();

      if (dataGrouped) {
        this.chart.data = dataGrouped;
      }

      this.chart.fontSize = 11;
      this.chart.innerRadius = (0, _core.percent)(10); // define data fields

      this.chart.dataFields.value = 'value';
      this.chart.dataFields.name = 'name';
      this.chart.dataFields.children = 'children';
      const level0SeriesTemplate = new _sunburst.SunburstSeries();
      level0SeriesTemplate.hiddenInLegend = false;
      this.chart.seriesTemplates.setKey('0', level0SeriesTemplate);
      level0SeriesTemplate.slices.template.adapter.add('tooltipText', this.formatTooltip); // this makes labels to be hidden if they don't fit

      level0SeriesTemplate.labels.template.truncate = true;
      level0SeriesTemplate.labels.template.hideOversized = true;
      level0SeriesTemplate.labels.template.adapter.add('rotation', (rotation, target) => {
        target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
        target.maxHeight = Math.abs(target.dataItem.slice.arc * (target.dataItem.slice.innerRadius + target.dataItem.slice.radius) / 2 * _core.math.RADIANS);
        return rotation;
      });
      const level1SeriesTemplate = level0SeriesTemplate.clone();
      this.chart.seriesTemplates.setKey('1', level1SeriesTemplate);
      level1SeriesTemplate.fillOpacity = 0.75;
      level0SeriesTemplate.hiddenInLegend = true;
      level1SeriesTemplate.slices.template.adapter.add('tooltipText', this.formatTooltip);
      this.chart.legend = new _charts.Legend();
      this.chart.legend.maxHeight = 120;
      this.chart.legend.scrollable = true;
    }

    getDataGrouped() {
      var _this$args$data;

      return (_this$args$data = this.args.data) === null || _this$args$data === void 0 ? void 0 : _this$args$data.reduce((array, obj) => {
        let key = array.find(el => el.name === obj.type);

        if (!key) {
          key = {
            name: obj.type
          };
          array.push(key);
        }

        key.children = key.children || [];
        key.children.push({
          name: obj.name,
          value: obj.valueAsNumber
        });
        return array;
      }, new Array());
    }

    updateChart() {
      if (this.chart) {
        const dataGrouped = this.getDataGrouped();

        if (dataGrouped) {
          this.chart.data = dataGrouped;
        }

        this.chart.invalidateData();
      }
    }

    willDestroy() {
      if (this.chart) {
        this.chart.dispose();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderChart", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateChart", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateChart"), _class.prototype)), _class));
  _exports.default = Am4chartsSunburst;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Am4chartsSunburst);
});