define("dome/objects/ac4/gantt-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AmCharts4GanttData {
    constructor(category, categoryLabel, start, end, task) {
      this.category = category;
      this.categoryLabel = categoryLabel;
      this.start = start;
      this.end = end;
      this.task = task;
    }

  }

  _exports.default = AmCharts4GanttData;
});