define("dome/components/alte/info-box", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="info-box {{if @color (concat "bg-" @color)}}">
    {{#if @icon}}
      <span class="info-box-icon">
        {{#if @iconPrefix}}
          <FaIcon class="mr-1" @prefix={{@iconPrefix}} @icon={{@icon}} />
        {{else}}
          <FaIcon class="mr-1" @icon={{@icon}} />
        {{/if}}
      </span>
    {{/if}}
    {{yield}}
    {{#if @loading}}
      <div class="overlay">
        <LoadingSpinner @size="4x" />
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "G5pGhL8D",
    "block": "[[[10,0],[15,0,[29,[\"info-box \",[52,[30,1],[28,[37,1],[\"bg-\",[30,1]],null]]]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,1],[14,0,\"info-box-icon\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[8,[39,2],[[24,0,\"mr-1\"]],[[\"@prefix\",\"@icon\"],[[30,3],[30,2]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,2],[[24,0,\"mr-1\"]],[[\"@icon\"],[[30,2]]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,5,null],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,0],[14,0,\"overlay\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@size\"],[\"4x\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@color\",\"@icon\",\"@iconPrefix\",\"@loading\",\"&default\"],false,[\"if\",\"concat\",\"fa-icon\",\"yield\",\"loading-spinner\"]]",
    "moduleName": "dome/components/alte/info-box.hbs",
    "isStrictMode": false
  });

  class AdminLteComponentsInfoBox extends _component.default {}

  _exports.default = AdminLteComponentsInfoBox;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AdminLteComponentsInfoBox);
});