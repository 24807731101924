define("dome/objects/ac4/stacked-history-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AmCharts4StackedHistoryData {
    constructor(currency, labels, elements) {
      this.currency = currency;
      this.labels = labels;
      this.elements = elements;
    }

  }

  _exports.default = AmCharts4StackedHistoryData;
});