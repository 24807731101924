define("dome/pods/exercice/route", ["exports", "bigint-money", "dome/objects/depense", "dome/objects/depenses-annuelles"], function (_exports, _bigintMoney, _depense, _depensesAnnuelles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Exercice extends Ember.Route {
    async model(params) {
      const depenses = await fetch('/assets/data/depenses.json');
      const payload = await depenses.json();
      const annualDepenses = payload.filter(dr => dr.exercice === params.exercice_id).map(dr => new _depense.default(parseInt(dr.exercice), dr.type, dr.soustype, new Date(dr.date), dr.libelle, dr.fournisseur, new _bigintMoney.default(dr.montant, 'EUR')));

      if (annualDepenses.length > 0) {
        return new _depensesAnnuelles.default(annualDepenses[0].exercice, annualDepenses);
      } else {
        return undefined;
      }
    }

    afterModel(model)
    /*, transition*/
    {
      if (model === undefined) {
        this.transitionTo('index');
      }
    }

  }

  _exports.default = Exercice;
});