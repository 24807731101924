define("dome/utils/moneys", ["exports", "bigint-money"], function (_exports, _bigintMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Moneys {
    constructor() {// Utility class
    }

    static format(intl, value, fractionDigits) {
      return intl.formatNumber(parseFloat(value.toFixed(fractionDigits ? fractionDigits : 2)), {
        currency: value.currency,
        minimumFractionDigits: fractionDigits ? fractionDigits : 2,
        style: 'currency'
      });
    }

  }

  _exports.default = Moneys;

  _defineProperty(Moneys, "ZERO_EUR", new _bigintMoney.default(0, 'EUR'));

  _defineProperty(Moneys, "ONE_EUR", new _bigintMoney.default(1, 'EUR'));
});